var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"card-body table-responsive bg-white"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-light d-flex flex-fill"},[_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-9"},[_c('h2',{staticClass:"lead"},[_c('b',[_vm._v(_vm._s(_vm.remolque.placa))])])]),_c('div',{staticClass:"col-3 text-center"},[(_vm.remolque.link_fotografia)?_c('img',{staticClass:"img-circle img-fluid",attrs:{"src":_vm.uri_docs + _vm.remolque.link_fotografia,"alt":"user-avatar"}}):_vm._e()])])])])])]),_c('div',{staticClass:"row"},_vm._l((_vm.remolque.paquetes),function(paquete){return _c('div',{key:paquete.id,staticClass:"col-md-12"},[_c('div',{staticClass:"card card-ligth card-outline collapsed-card"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_c('i',{staticClass:"far fa-folder"}),_vm._v(" "+_vm._s(paquete.nombre)+" ")]),_vm._m(0,true)]),_c('div',{staticClass:"card-body table-responsive"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-hover table-sm text-nowrap",attrs:{"id":"docs"}},[_vm._m(1,true),_c('tbody',_vm._l((paquete.documentos),function(documento){return _c('tr',{key:documento.id,class:documento.pivot.relevante &&
                        !_vm.validaDoc(_vm.remolque.documentos_files, documento, 2)
                          ? 'alert-default-danger'
                          : 'alert-default-success'},[_c('td',{staticClass:"text-wrap",domProps:{"textContent":_vm._s(documento.descripcion)}}),_c('td',[(_vm.validaDoc(_vm.remolque.documentos_files, documento, 1))?_c('p',[_vm._v(" "+_vm._s(_vm.remolque.documentos_files.find((file) => file.documento_id === documento.id).referencia)+" ")]):_vm._e()]),_c('td',[(_vm.validaDoc(_vm.remolque.documentos_files, documento, 1))?_c('p',[_vm._v(" "+_vm._s(_vm.remolque.documentos_files.find((file) => file.documento_id === documento.id).emisor)+" ")]):_vm._e()]),_c('td',{staticClass:"text-center"},[(_vm.validaDoc(_vm.remolque.documentos_files, documento, 1))?_c('p',[_vm._v(" "+_vm._s(_vm.remolque.documentos_files.find((file) => file.documento_id === documento.id).fecha_emision)+" ")]):_vm._e()]),_c('td',{staticClass:"text-center"},[(_vm.validaDoc(_vm.remolque.documentos_files, documento, 1))?_c('p',[_vm._v(" "+_vm._s(_vm.remolque.documentos_files.find((file) => file.documento_id === documento.id).fecha_vencimiento)+" ")]):_vm._e()]),_c('td',{staticClass:"text-center"},[_c('i',{staticClass:"fa",class:_vm.btnVerificado[paquete.id + '-' + documento.id] ? 
                            'fa-check text-success' : 
                            'fa-times-circle text-danger'})]),_c('td',[(_vm.validaDoc(_vm.remolque.documentos_files, documento, 2))?_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"target":"_blank","href":_vm.uri_docs + _vm.remolque.documentos_files.find((file) => file.documento_id === documento.id).link}},[_c('i',{staticClass:"fa fa-download"})]):_vm._e()])])}),0)])])])])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-plus"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark"},[_c('tr',[_c('th',[_vm._v("Documento")]),_c('th',[_vm._v("Referencia")]),_c('th',[_vm._v("Emisor")]),_c('th',[_vm._v("Fecha Emisión")]),_c('th',[_vm._v("Fecha Vencimiento")]),_c('th',[_vm._v("Verificado")]),_c('th',[_vm._v("Link")])])])
}]

export { render, staticRenderFns }