<template>
  <div class="card-body">
    <div class="form-group col-md-12">
      <div id="accordion-documentos">
        <div class="card m-1 bg-frontera">
          <div class="card-header">
            <a
              data-toggle="collapse"
              href="#collapse_conductor"
              aria-expanded="false"
              class="d-block w-100 collapsed"
              ><h4 class="card-title w-100 text-white">CONDUCTOR</h4></a
            >
          </div>
          <div id="collapse_conductor" data-parent="#accordion-documentos" class="collapse">
            <DocumentoConductor ref="DocumentoConductor"></DocumentoConductor>
          </div>
        </div>
        <div class="card m-1 bg-frontera">
          <div class="card-header">
            <a
              data-toggle="collapse"
              href="#collapse_vehiculo"
              aria-expanded="false"
              class="d-block w-100 collapsed"
              ><h4 class="card-title w-100 text-white">VEHÍCULO</h4></a
            >
          </div>
          <div id="collapse_vehiculo" data-parent="#accordion-documentos" class="collapse">
            <DocumentoVehiculo ref="DocumentoVehiculo"></DocumentoVehiculo>
          </div>
        </div>
        <div class="card m-1 bg-frontera">
          <div class="card-header">
            <a
              data-toggle="collapse"
              href="#collapse_remolque"
              aria-expanded="false"
              class="d-block w-100 collapsed"
              ><h4 class="card-title w-100 text-white">REMOLQUE</h4></a
            >
          </div>
          <div id="collapse_remolque" data-parent="#accordion-documentos" class="collapse">
            <DocumentoRemolque ref="DocumentoRemolque"></DocumentoRemolque>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import DocumentoConductor from "./DocumentoConductor.vue";
import DocumentoVehiculo from "./DocumentoVehiculo.vue";
import DocumentoRemolque from "./DocumentoRemolque.vue";

export default {
    name: "InspeccionDocumentos",
    components: { 
      DocumentoConductor,
      DocumentoVehiculo,
      DocumentoRemolque,
    },

    data() {
      return {
        conductor: null,
        vehiculo: null,
        remolque: null,

        listaImagenes: [],
        link_imagen: null,
        array_Imagenes: [],
        modal_id: null,
        modal_link: null,
        files: null,
        bandera: 0,
      };
    },
    methods: {
      async getIndex() {
        this.conductor_id = this.$parent.conductor_id;
        this.vehiculo_id = this.$parent.vehiculo_id;
        this.remolque_id = this.$parent.remolque_id;

        // Se busca la información del conductor
        if (this.conductor_id) {
          this.$refs.DocumentoConductor.getConductor(this.conductor_id);
        }
        // Se busca la información del vehículo
        if (this.vehiculo_id) {
          this.$refs.DocumentoVehiculo.getVehiculo(this.vehiculo_id);
        }
        // Se busca la información del remolque
        if (this.remolque_id) {
          this.$refs.DocumentoRemolque.getRemolque(this.remolque_id);
        }
      },
    },
};
</script>
