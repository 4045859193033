<template>
  <div class="row">
    <div class="card-body table-responsive bg-white">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
          <div class="card bg-light d-flex flex-fill">
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-9">
                  <h2 class="lead"><b>{{ conductor.nombres }} {{ conductor.apellidos }}</b></h2>
                  <p class="text-muted text-sm"><b>Documento: </b>{{conductor.numero_documento}}</p>
                  <ul class="ml-4 mb-0 fa-ul text-muted">
                    <li 
                    v-if="conductor.email"
                      class="small"
                    >
                      <span class="fa-li">
                        <i class="fas fa-lg fa-envelope"></i>
                      </span>
                      {{ conductor.email }}
                    </li>
                    <li 
                    v-if="conductor.celular_principal"
                      class="small"
                    >
                      <span class="fa-li">
                        <i class="fas fa-lg fa-phone"></i>
                      </span> 
                      {{ conductor.celular_principal }}
                    </li>
                    <li 
                      v-if="conductor.celular_alterno"
                      class="small"
                    >
                      <span class="fa-li">
                        <i class="fas fa-lg fa-phone"></i>
                      </span> 
                      {{ conductor.celular_alterno }}
                    </li>
                  </ul>
                </div>
                <div class="col-3 text-center">
                <img 
                  v-if="conductor.link_fotografia"
                  :src="uri_docs + conductor.link_fotografia" 
                  alt="user-avatar" 
                  class="img-circle img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-12"
          v-for="paquete in conductor.paquetes"
          :key="paquete.id"
        >
          <div class="card card-ligth card-outline collapsed-card">
            <div class="card-header pt-2 pb-2">
              <h3 class="card-title">
                <i class="far fa-folder"></i>
                {{ paquete.nombre }}
              </h3>
              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="card-body table-responsive">
              <div class="row">
                <div class="col-md-12">
                  <table
                    id="docs"
                    class="table table-bordered table-hover table-sm text-nowrap"
                  >
                    <thead class="bg-dark">
                      <tr>
                        <th>Documento</th>
                        <th>Referencia</th>
                        <th>Emisor</th>
                        <th>Fecha Emisión</th>
                        <th>Fecha Vencimiento</th>
                        <th>Verificado</th>
                        <th>Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="documento in paquete.documentos"
                        :key="documento.id"
                        v-bind:class="
                          documento.pivot.relevante &&
                          !validaDoc(conductor.documentos_files, documento, 2)
                            ? 'alert-default-danger'
                            : 'alert-default-success'
                        "
                      >
                        <td class="text-wrap" v-text="documento.descripcion"></td>
                        <td>
                          <p v-if="validaDoc(conductor.documentos_files, documento, 1)">
                            {{ conductor.documentos_files.find((file) => file.documento_id === documento.id).referencia}}
                          </p>
                        </td>
                        <td>
                          <p v-if="validaDoc(conductor.documentos_files, documento, 1)">
                            {{ conductor.documentos_files.find((file) => file.documento_id === documento.id).emisor }}
                          </p>
                        </td>
                        <td class="text-center">
                          <p v-if="validaDoc(conductor.documentos_files, documento, 1)">
                            {{ conductor.documentos_files.find((file) => file.documento_id === documento.id).fecha_emision }}
                          </p>
                        </td>
                        <td class="text-center">
                          <p v-if="validaDoc(conductor.documentos_files, documento, 1)">
                            {{ conductor.documentos_files.find((file) => file.documento_id === documento.id).fecha_vencimiento }}
                          </p>
                        </td>
                        <td class="text-center">
                          <i 
                            class="fa"
                            :class="
                              btnVerificado[paquete.id + '-' + documento.id] ? 
                              'fa-check text-success' : 
                              'fa-times-circle text-danger' "
                          ></i>
                        </td>
                        <td>
                          <a
                            target="_blank"
                            class="btn btn-primary btn-sm"
                            :href="uri_docs + conductor.documentos_files.find((file) => file.documento_id === documento.id).link"
                            v-if="validaDoc(conductor.documentos_files, documento, 2)"
                          >
                            <i class="fa fa-download"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DocumentoConductor",
  data() {
    return {
      cargando: false,
      conductor: [],
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      btnVerificado: {},
    };
  },
  methods: {
    getConductor(id) {
      axios.get("/api/admin/conductores/" + id).then((response) => {
        this.conductor = response.data[0];
        this.getVerificado();
      });
    },

    validaDoc(files, documento, tipo) {
      if (files.find((file) => file.documento_id === documento.id) !== undefined) {
        if (tipo == 2) {
          if (files.find((file) => file.documento_id === documento.id).link !== "") {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    getVerificado() {
      for (let i = 0; i < this.conductor.paquetes.length; i++) {
        const paquete = this.conductor.paquetes[i];
        for (let j = 0; j < paquete.documentos.length; j++) {
          const documento = paquete.documentos[j];
          let verif = this.conductor.documentos_files.find(
            (file) => file.documento_id === documento.id
          );

          if (verif !== undefined) {
            this.btnVerificado[
              paquete.id + "-" + documento.id
            ] = this.conductor.documentos_files.find(
              (file) => file.documento_id === documento.id
            ).verificado;
          } else {
            this.btnVerificado[paquete.id + "-" + documento.id] = false;
          }
        }
      }
    },
  },
};
</script>
